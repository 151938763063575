<template>
  <v-row>
    <v-col lg="12">
      <v-form ref="formProfesion" v-model="valid" lazy-validation>
        <Form :modelo="model" :fields="fieldProfesion" :edit="edit" :create="false" />
      </v-form>

      <v-layout row wrap>
        <v-flex lg3 md4 sm6 xs12 v-if="titulo_pdf!=null">
          <v-btn color="info" dark @click="showDocumento(doc_url+titulo_pdf)">
            <v-icon dark left>picture_as_pdf</v-icon>Documentos
          </v-btn>
        </v-flex>
      </v-layout>

      <v-dialog v-model="dialog" max-width="80%" v-show="titulo_pdf!=null">
        <v-card>
          <v-card-title class="title">
            Documentos Presentados
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" icon small text @click.native="dialog=false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <div id="previewPDF"></div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="default darken-1" text @click="dialog = false">Cerrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>
<script>
import Form from "@/common/util/crud/Form";

export default {
  props: {
    profesion: Object,
    id: Number,
    urlBase: String,
    titulo_pdf: String
  },
  components: { Form },
  data() {
    return {
      valid: false,
      consulta: false,
      edit: false,
      dialog: false,
      fieldProfesion: [],
      titulo: "Información del Titulo Profesional",
      doc_url: this.$docUrl
    };
  },
  mounted() {
    this.fieldProfesion = [
      {
        label: "Universidad Incio Estudios",
        model: "universidadInicio",
        type: "text",
        class: "lg6 md4 sm6 xs12",
        classField: "required",
        rules: "requiredSelect"
      },
      {
        label: "Universidad Egreso",
        model: "universidadEgreso",
        type: "text",
        class: "lg6 md4 sm6 xs12",
        classField: "required",
        rules: "requiredSelect"
      },
      {
        label: "Universidad Licenciatura",
        model: "universidadLicenciatura",
        type: "text",
        class: "lg6 md4 sm6 xs12",
        classField: "required",
        rules: "requiredSelect"
      },
      {
        label: "Tramite Licenciatura",
        model: "lugarVerificacionTitulo",
        type: "text",
        class: "lg6 md4 sm6 xs12",
        classField: "required",
        rules: "requiredSelect"
      },
      {
        label: "Mencion",
        model: "mencion",
        type: "text",
        class: "lg3 md4 sm6 xs12",
        classField: "required",
        rules: "requiredSelect"
      },
      {
        label: "Numero Titulo",
        model: "numeroTitulo",
        type: "text",
        class: "lg3 md4 sm6 xs12",
        classField: "required",
        rules: "requiredSelect"
      },
      {
        label: "Fecha Expedicion",
        model: "fechaExpedicionTitulo",
        type: "date",
        birthday: false,
        class: "lg3 md4 sm6 xs12",
        classField: "required",
        rules: "requiredSelect"
      },
      {
        label: "Fotocopia Legalizada",
        model: "fotocopiaTituloLegalizada",
        type: "text",
        class: "lg3 md4 sm6 xs12",
        classField: "required",
        rules: "requiredSelect"
      }
    ];
  },
  computed: {
    model() {
      return this.$store.state.abogado.profesion;
    }
  },
  methods: {
    modificar(data) {
      if (this.$refs.formProfesion.validate()) {
        this.consulta = true;
        this.$http
          .put(`${this.urlBase}/${data.id}`, data)
          .then(response => {
            this.consulta = false;
            this.edit = false;
          })
          .catch(error => {
            this.consulta = false;
          });
      }
    },
    cancelar() {
      this.edit = false;
    },
    showDocumento(pdf) {
     
      this.dialog = true;
      const options = {
        height: "800px",
        pdfOpenParams: {
          pagemode: "thumbs",
          navpanes: 0,
          toolbar: 0,
          statusbar: 1,
          view: "FitV"
        }
      };
      PDFObject.embed(pdf, "#previewPDF", options);
    }
  }
};
</script>

